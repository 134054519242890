import { TopNav } from '../TopNav';
import type { MenuContent, MenuLink } from '../TopNav';
import logoSvg from '../../ds_tels_logo.png';
import { MdLock, Text } from '@dsx/react';
import { useLocation } from 'react-router-dom';
import { useDisplayType } from '../../hooks';
import type { DisplayTarget } from '../../types';
import styles from './TopNavTels.module.css';

const MenuVariantPaths = {
	CONNECT: '/connect',
	SERVICE_PROVIDER: '/service-provider',
};

const registerLink =
	'https://smart.tels.net/SMART/ServiceProviderPortal/Registration?utm_source=sign-up-top&utm_medium=landing-page&utm_campaign=rsa';

const menuContent: MenuContent[] = [
	{
		primary: {
			path: '/technology/building-management-platform/',
			label: 'Platform',
			value: '/technology/building-management-platform/',
			openInNewTab: false,
		},
		secondary: [],
	},
	{
		primary: {
			path: '/services',
			label: 'Services',
			value: '',
			openInNewTab: false,
		},
		secondary: [
			{
				path: '/services',
				label: 'All Services',
				value: '/services',
				openInNewTab: false,
			},
			{
				path: '/services/routine-and-emergency-repairs',
				label: 'Routine & Emergency Repairs',
				value: '/services/routine-and-emergency-repairs',
				openInNewTab: false,
			},
			{
				path: '/services/planned-projects',
				label: 'Planned Projects',
				value: '/services/planned-projects',
				openInNewTab: false,
			},
			{
				path: '/services/recurring-maintenance-programs',
				label: 'Recurring Maintenance Programs',
				value: '/services/recurring-maintenance-programs',
				openInNewTab: false,
			},
		],
	},
	{
		primary: {
			path: '/solutions',
			label: 'Solutions',
			value: '',
			openInNewTab: false,
		},
		secondary: [
			{
				path: '/solutions',
				label: 'All Solutions',
				value: '/solutions',
				openInNewTab: false,
			},
			{
				path: '/solutions/tels-plus/',
				label: 'TELS+',
				value: '/solutions/tels-plus/',
				openInNewTab: false,
			},
			{
				path: '/services/recurring-maintenance-programs/fire-protection',
				label: 'Fire Protection',
				value: '/services/recurring-maintenance-programs/fire-protection',
				openInNewTab: false,
			},
			{
				path: '/solutions/water-management-plan/',
				label: 'Water Management Plan',
				value: '/solutions/water-management-plan/',
				openInNewTab: false,
			},
			{
				path: '/solutions/indoor-air-quality',
				label: 'Indoor Air Quality',
				value: '/solutions/indoor-air-quality/',
				openInNewTab: false,
			},
			{
				path: '/services/recurring-maintenance-programs/medical-waste-management',
				label: 'Medical Waste Management',
				value: '/services/recurring-maintenance-programs/medical-waste-management',
				openInNewTab: false,
			},
		],
	},
];

const menuContentServiceProvider: MenuContent[] = [
	{
		primary: {
			path: '/service-provider/',
			label: 'Service Provider',
			value: '/service-provider/',
			openInNewTab: false,
		},
		secondary: [],
	},
	{
		primary: {
			path: '/service-provider/training/',
			label: 'Training Videos',
			value: '/service-provider/training/',
			openInNewTab: false,
		},
		secondary: [],
	},
	{
		primary: {
			path: '',
			label: 'TELS Connect Walkthrough',
			value: '/assets/pdf/17877-TELS-sp-tech-overview-sheet-LoResFin.pdf',
			openInNewTab: true,
		},
		secondary: [],
		isAssetLink: true,
	},
	{
		primary: {
			path: '',
			label: 'Expectations',
			value: '/assets/pdf/TBS-Interior-Service-Provider-Expectations.pdf',
			openInNewTab: true,
		},
		secondary: [],
		isAssetLink: true,
	},
	{
		primary: {
			path: 'tel:888-225-7729',
			label: '888-225-7729',
			value: 'tel:888-225-7729',
			openInNewTab: false,
		},
		secondary: [],
	},
];

const isPathMatch = (pathName: string, value: string) => {
	return pathName.startsWith(value);
};

const getCtaLinks = (
	pathname: string,
	displayTarget: DisplayTarget
): MenuLink[] => {
	if (isPathMatch(pathname, MenuVariantPaths.CONNECT)) {
		if (displayTarget === 'desktop') {
			return [
				{
					path: 'sign-in',
					label: 'Sign in',
					value: 'https://smart.tels.net/SMART/ServiceProviderPortal',
					openInNewTab: true,
					icon: MdLock,
				},
			];
		}
		return [
			{
				path: 'register',
				label: 'Register to Get Started',
				value: registerLink,
				openInNewTab: true,
			},
			{
				path: 'sign-in',
				label: 'Sign in',
				value: 'https://smart.tels.net/SMART/ServiceProviderPortal',
				openInNewTab: true,
				icon: MdLock,
			},
		];
	}

	return [
		{
			path: 'contact/',
			label: 'Contact us',
			value: 'contact/',
			openInNewTab: false,
		},
		{
			path: 'sign-in',
			label: 'Sign in',
			value: isPathMatch(pathname, MenuVariantPaths.CONNECT)
				? 'https://smart.tels.net/SMART/ServiceProviderPortal'
				: 'https://www.tels.net/TELSLogin/signin.aspx',
			openInNewTab: true,
			icon: MdLock,
		},
	];
};

const TopNavTels = () => {
	const logo = {
		svg: logoSvg,
		alt: 'DS TELS Logo',
		href: '/',
	};

	const { pathname } = useLocation();
	const displayTarget = useDisplayType();
	const pathHeader =
		displayTarget == 'desktop' &&
		isPathMatch(pathname, MenuVariantPaths.CONNECT) ? (
			<div className={styles['header-items']}>
				<a
					className={styles['primary-nav-link']}
					href={registerLink}
					target="_blank"
					rel="noopener noreferrer"
				>
					<div className={styles['primary-menu-item-content']}>
						<Text variant="menu-item">Register to Get Started</Text>
					</div>
				</a>
				<Text variant="menu-item" className={styles['header-item']}>
					Already a Direct Supply TELS Service Provider? Sign in to TELS
					Connect.
				</Text>
			</div>
		) : null;
	const ctaLinks = getCtaLinks(pathname, displayTarget);
	// TODO: allow for trailing slash in path
	const primaryContent = isPathMatch(pathname, MenuVariantPaths.CONNECT)
		? []
		: isPathMatch(pathname, MenuVariantPaths.SERVICE_PROVIDER)
		? menuContentServiceProvider
		: menuContent;

	return (
		<TopNav
			activePath={pathname}
			initMenuContent={primaryContent}
			ctaLinks={ctaLinks}
			logo={logo}
			header={pathHeader}
		/>
	);
};

export { TopNavTels };
